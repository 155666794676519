.mobile-box {
  margin: auto
}

.mobile-card {
  min-width: 120px;
}

.card {
  min-width: 250px
}

.desktop-box {
  width: 100%;
}

.desktop-top {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 105px;
  top: 0;
  width: calc(100% - 100px);
}

.mobile-top {
  min-width: 100%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.desktop-table-box, .mobile-table-box {
  margin-top: 20px;
  height: 1000px;
  margin-bottom: 20px;
}

.desktop-table-box {
  width: 100%;
}

.mobile-table-box {
  width: 100%;
  overflow-x: scroll;
}

.desktop-table {
  width: 80%;
}

.mobile-table {
  width: fit-content;
  overflow-x: scroll;
}

.furhaven.All.primary {
  background-color: #84B375
}

.furhaven.All.secondary {
  background-color: #5b7d52
}

.furhaven.All.tertiary {
  background-color: #7d5265
}

.furhaven.All.error {
  background-color: #dd3e04
}

.furhaven.kentucky.primary {
  background-color: #0099FF
}

.furhaven.kentucky.secondary {
  background-color: #0087b3
}

.furhaven.kentucky.tertiary {
  background-color: #b35100
}

.furhaven.kentucky.error {
  background-color: #bf163e
}

.furhaven.pennsylvania.primary {
  background-color: #CEB08D
}

.furhaven.pennsylvania.secondary {
  background-color: #908163
}

.furhaven.pennsylvania.tertiary {
  background-color: #666b90
}

.furhaven.pennsylvania.error {
  background-color: #ea2329
}

p.cancelled {
  color: #F4846D;
}

p.error {
  color: #DD2700;
}

p.created {
  color: #9FA999;
}

p.pulled {
  color: #8DB973;
}

p.built {
  color: #85DA52;
}

p.staged {
  color: #64D124;
}

p.shipped {
  color: #42af33;
}